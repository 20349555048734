import { httpClient } from '../../utilities/httpClient.js';
const organizationsStore = {
    state() {
        return {
            organizations: [],
            organizationsHaveLoaded: false,
            organizationsLastLoaded: 0,
        }
    },
    getters: {
        getOrganizationsList(state) {return state.organizations},
        getOpportunitiesHaveLoaded(state) {return state.organizationsHaveLoaded},
        getOpportunitiesLastLoaded(state) {return state.organizationsLastLoaded},
    },
    actions: {
        getAllOrganizations(context){
            // IF NOT ENOUGH TIME HAS PASSED, RELOAD FROM STORE
            /*if(parseInt(new Date().getTime()) - parseInt(context.getters.getUsersLastLoaded) < 5000){
                return;
            }*/
            // ELSE RELOAD FROM SERVER
            return new Promise((resolve, reject) => {
                context.commit('setOrganizationsHaveLoaded', false);
                context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/organizations', { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    context.commit('setOrganizationsHaveLoaded', true);
                    context.commit('setLoadingOverlayOn', false);
                    context.commit('setOrganizationsLastLoaded', new Date().getTime());
                    context.commit('setOrganizationsList', response.data.data);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        createOrganization(context, payload){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.post(context.rootState.BASE_URL + '/admin/organizations', 
                payload,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Organization created!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve(response);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        editOrganization(context, payload){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.put(context.rootState.BASE_URL + '/admin/organizations/' + payload.id, 
                payload.data,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    alert('Organization edited!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    // Update user in list?
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
    },
    mutations: {
        setOrganizationsList(state, organizations) { state.organizations = organizations },
        setOrganizationsHaveLoaded(state, organizationsHaveLoaded) {state.organizationsHaveLoaded = organizationsHaveLoaded},
        setOrganizationsLastLoaded(state, organizationsLastLoaded) {state.organizationsLastLoaded = organizationsLastLoaded},
    }
};

export default organizationsStore;
