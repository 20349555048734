import { httpClient } from '../../utilities/httpClient.js';
const notificationsStore = {
    state() {
        return {
            notifications: [],
            currentNotification:'',
            currentNotificationDocs:[],
            notificationsHaveLoaded: false
        }
    },
    getters: {
        getNotificationsList(state) {
            // SORT FROM RECENT TO OLDER
            return state.notifications.sort((a, b) => {
                return new Date(b.creation_date_utc) - new Date(a.creation_date_utc);
            });
        },
        getCurrentNotification(state) {return state.currentNotification},
        getCurrentNotificationDocs(state) {return state.currentNotificationDocs},
        getNotificationsHaveLoaded(state) {return state.notificationsHaveLoaded},
    },
    actions: {
        getNotifications(context){
            // Construct the URL based on type of bid entity (user or opportunity)
            // entity: {type, id}
            var queryURL = context.rootState.BASE_URL + '/admin/notifications?lower_date=2021-01-01';
            return new Promise((resolve, reject) => {
                context.commit('setNotificationsHaveLoaded', false);
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(queryURL, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Notifications loaded:');
                    console.log(response.data.data);
                    context.commit('setNotificationsHaveLoaded', true);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setNotifications', response.data.data);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setNotificationsHaveLoaded', false);
                    reject();
                }); 
            });// PROMISE
        },
        sendNotification(context, payload){
            /* 
            {
                "content": "string",
                "data": {
                    "data": {},
                    "route": "string"
                },
                "registration_types": [
                    "string"
                ],
                "seb_certifications": [
                    "string"
                ],
                "title": "string",
                "user_id": "string",
                "usi_codes": [
                    "string"
                ]
                }
             */
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.post(context.rootState.BASE_URL + '/admin/real-notification', 
                {
                    content: payload.content,
                    title: payload.title,
                    usi_codes: payload.categorization.usi_codes,
                    data: {
                        data: '',
                        route: '/notifications'
                    }
                },
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then(async (response) => {
                    console.log('Notification sent!');
                    console.log(response);
                    //context.commit('setLoadingOverlayOn', false);
                    context.dispatch('createNotification', payload);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    console.log('Notification ERROR!');
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        createNotification(context, payload){
            // THIS METHOD CREATES THE DATABASE RECORD FOR THE NOTIFICATION - SHOULD BE USED AFTER SENDING THE ACTUAL NOTIFICATION SUCCESSFULLY
            /*
             {
                "admin_id": "string",
                "categorization": {
                    "naics": "string",
                    "usi_codes": ["string"],
                    "organization_type": "string",
                    "registration_type": "string",
                    "seb_certification": "string",
                    "tags": [
                    "string"
                    ]
                },
                "content": "string",
                "state": "string",
                "title": "string",
                }
             */
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.post(context.rootState.BASE_URL + '/admin/notification', 
                payload,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Notification created!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
    },
    mutations: {
        setNotifications(state, notifications) { state.notifications = notifications },
        setCurrentNotification(state, currentNotification) { state.currentNotification = currentNotification },
        setCurrentNotificationDocs(state, currentNotificationDocs) { state.currentNotificationDocs = currentNotificationDocs },
        setNotificationsHaveLoaded(state, notificationsHaveLoaded) {state.notificationsHaveLoaded = notificationsHaveLoaded},
    }
};

export default notificationsStore;
