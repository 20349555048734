import { httpClient } from '../../utilities/httpClient.js';
const bidsStore = {
    state() {
        return {
            bids: [],
            currentBidsEntity: 'user', // user or opportunity
            currentBid:{},
            bidsHaveLoaded: false,
            bidsLastLoaded:0,
            currentBidDocs:[]
        }
    },
    getters: {
        getBidsList(state) {return state.bids},
        getCurrentBidsEntity(state) {return state.currentBidsEntity},
        getCurrentBid(state) {return state.currentBid},
        getCurrentBidDocs(state) {return state.currentBidDocs},
        getBidsHaveLoaded(state) {return state.bidsHaveLoaded},
        getBidsLastLoaded(state) {return state.bidsLastLoaded},
    },
    actions: {
        getBids(context, entity){
            // Construct the URL based on type of bid entity (user or opportunity)
            // entity: {type, id}
            var queryURL = context.rootState.BASE_URL + '/admin/bids?' + entity.type + '_id=' + entity.id;
            return new Promise((resolve, reject) => {
                context.commit('setBidsHaveLoaded', false);
                context.commit('setLoadingOverlayOn', true);
                httpClient.get(queryURL, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(entity.type + ' bids loaded:');
                    console.log(response);
                    context.commit('setBidsHaveLoaded', true);
                    context.commit('setLoadingOverlayOn', false);
                    context.commit('setBidsLastLoaded', new Date().getTime());
                    context.commit('setBids', response.data.data);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    context.commit('setBidsHaveLoaded', false);
                    reject();
                }); 
            });// PROMISE
        },
        getBidDocs(context, id){
            return new Promise((resolve, reject) => {
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/admin/documents?bid_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setCurrentBidDocs', response.data.data);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        editBid(context, payload){
            // PAYLOAD
            /*
            {
                id,
                data: {
                    state: pending, approved, rejected, winner,
                    fields: []
                }
            }
             */
            return new Promise((resolve, reject) => {
                context.commit('setBidsHaveLoaded', false);
                context.commit('setLoadingOverlayOn', true);
                httpClient.put(context.rootState.BASE_URL + '/admin/bid/' + payload.id, 
                payload.data,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    context.commit('setLoadingOverlayOn', false);
                    // RELOAD SINGLE BID TO REFLECT CHANGES
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        }
    },
    mutations: {
        setBids(state, bids) { state.bids = bids },
        setCurrentBidDocs(state, currentBidDocs) {state.currentBidDocs = currentBidDocs},
        setBidsHaveLoaded(state, bidsHaveLoaded) {state.bidsHaveLoaded = bidsHaveLoaded},
        setBidsLastLoaded(state, bidsLastLoaded) {state.bidsLastLoaded = bidsLastLoaded},
        setCurrentBidsEntity(state, currentBidsEntity) {state.currentBidsEntity = currentBidsEntity},
        setCurrentBid(state, currentBid) {state.currentBid = currentBid},
    }
};

export default bidsStore;
