import { createStore } from 'vuex';
import { httpClient } from '../utilities/httpClient.js';
import router from '../router/index.js';
import VuexPersistence from 'vuex-persist';

import authStore from './modules/auth.js';
import usersStore from './modules/users.js';
import opportunitiesStore from './modules/opportunities.js';
import bidsStore from './modules/bids.js';
import pagesStore from './modules/pages.js';
import dataStore from './modules/data.js';
import announcementsStore from './modules/announcements.js';
import notificationsStore from './modules/notifications.js';
import organizationsStore from './modules/organizations.js';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({ auth: state.auth }),
});



export default createStore({
  state() {
    console.log('DEBUG', process.env.VUE_APP_DEBUG);
    return {
      // Strings
      //http://mccseb.azurewebsites.net/
      //https://api.sebconnect.com
      BASE_URL: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'http://mccseb.azurewebsites.net/',
      DEBUG: process.env.VUE_APP_DEBUG === 'true',
      // Current app state, nav state
      appVersion: require('../../package.json').version,
      definitions: {}, // Make sure USI codes are here, and parents
      sebNavCurrent: 'users',
      navInProgress: false,
      loadingOverlayOn: false,
      confirmOverlayOn: false,
      confirmOverlayAction: function () { },
      showBreadcrumb: true,
      showBackButton: false,
      currentUser: '',
      currentOpportunity: '',
      currentBid: '',
      currentAnnouncement: '',
      currentNotification: '',
      currentPage: '',
      currentOrganization: '',
      lockScroll: false,
    }
  },
  getters: {
    isDebug(state) { return state.DEBUG },
    getDefinitions(state) { return state.definitions },
    getSebNavCurrent(state) { return state.sebNavCurrent },
    getLoadingOverlayOn(state) { return state.loadingOverlayOn },
    getShowBreadcrumbs(state) { return state.showBreadcrumbs },
    getCurrentUser(state) { return state.currentUser },
    getCurrentOpportunity(state) { return state.currentOpportunity },
    getShowBackButton(state) { return state.showBackButton },
    getLockScroll(state) { return state.lockScroll },
    getConfirmOverlayOn(state) { return state.confirmOverlayOn },
    getCurrentOrganization(state) { return state.currentOrganization },
  },
  mutations: {
    setDefinitions(state, definitions) { state.definitions = definitions },
    setSebNavCurrent(state, sebNavCurrent) { state.sebNavCurrent = sebNavCurrent },
    setNavInProgress(state, navInProgress) { state.navInProgress = navInProgress },
    setLoadingOverlayOn(state, loadingOverlayOn) { state.loadingOverlayOn = loadingOverlayOn },
    setShowBreadcrumbs(state, showBreadcrumbs) { state.showBreadcrumbs = showBreadcrumbs },
    setShowBackButton(state, showBackButton) { state.showBackButton = showBackButton },
    setLockScroll(state, lockScroll) {
      state.lockScroll = lockScroll;
      var body = document.body;
      if (state.lockScroll) {
        body.classList.contains('lock-scroll') ? null : body.classList.add('lock-scroll');
      } else {
        body.classList.remove('lock-scroll');
      }
    },
    setConfirmOverlayOn(state, confirmOverlayOn) { state.confirmOverlayOn = confirmOverlayOn },
    setConfirmOverlayAction(state, confirmOverlayAction) { state.confirmOverlayAction = confirmOverlayAction },
  },
  actions: {
    goBack() {
      router.go(-1);
    },
    handleError(context, error) {
      console.log(error.response);

      switch (error.response.status) {
        case 400:
          // Server error or unidentified error
          alert('ERROR: 400 - Server error');
          break;
        case 401:
          // Session token error
          alert('ERROR: 401 - Invalid Token');
          context.dispatch('handleSessionEnd');
          // router.replace('/login');
          break;
        case 403:
          // Forbidden
          alert('ERROR: 403 - Forbidden');
          break;
        case 404:
          // Not found error
          alert('ERROR: 404 - Not Found');
          break;
        case 409:
          // Process error - use custom messages for these
          alert('Error: ' + error.response.data.data);
          break;
        default:
          // ALL other cases
          alert('Error: undefined');
      }
    },
    getSystemDefinitions(context) {
      return new Promise((resolve, reject) => {
        httpClient.get(context.rootState.BASE_URL + '/definitions', {
        })
          .then((response) => {
            // LOG to see definitions for USI codes
            console.log(response);
            context.commit('setDefinitions', response.data.data);
            resolve();
          })
          .catch((error) => {
            context.dispatch('handleError', error);
            console.log(error.response);
            reject();
          });
      });// PROMISE
    },
    setCurrentUser(context, id) {
      context.state.currentUser = context.getters.getUsersList.find((x) => {
        return x.id == id;
      });
      if (context.state.currentUser == null) {
        context.state.currentUser = 'none';
        alert('Error - User not found');
      } else {
        console.log('User set to: ' + context.state.currentUser.id);
      }
    },
    setCurrentOpportunity(context, id) {
      context.state.currentOpportunity = context.getters.getOpportunitiesList.find((x) => {
        return x.id == id;
      });
      if (context.state.currentOpportunity == null) {
        context.state.currentOpportunity = 'none';
        alert('Error - Opportunity not found');
      } else {
        console.log('Opportunity set to: ' + context.state.currentOpportunity.id);
      }
    },
    setCurrentOrganization(context, id) {
      context.state.currentOrganization = context.getters.getOrganizationsList.find((x) => {
        return x.id == id;
      });
      if (context.state.currentOrganization == null) {
        context.state.currentOrganization = 'none';
        alert('Error - Opportunity not found');
      } else {
        console.log('Opportunity set to: ' + context.state.currentOrganization.id);
      }
    },
    manageBackButton(context, path) {
      if (
        path.includes('/users/single-user') ||
        path == '/users/new-user' ||
        path.includes('/opportunities/single-opportunity') ||
        path == '/opportunities/new-opportunity' ||
        path.includes('/announcements/single-announcement') ||
        path == '/announcements/new-announcement' ||
        path.includes('/pages/single-page') ||
        path == '/pages/new-page'
      ) {
        console.log('MANAGED');
        context.commit('setShowBackButton', true);
      } else {
        console.log('NOT MANAGED');
        context.commit('setShowBackButton', false);
      }
    },
    uploadDocument(context, payload) {
      // PAYLOAD
      /*
      {
        parent_id,
        parent_type,
        doc_data: formData
      }
       */
      // GENERATE FormData ENTITY
      console.log('FILE SIZE: ' + payload.doc_data.file.size);
      if (payload.doc_data.file.size > 157286400) {
        alert('ERROR - File exceeds size limit. Must be smaller than 150 MB');
        throw 403;
      }
      var formData = new FormData();
      formData.append('label', payload.doc_data.label);
      formData.append('file_name', payload.doc_data.file_name);
      formData.append('file', payload.doc_data.file);
      formData.append('description', payload.doc_data.description);
      // SET DOCUMENT PARENT
      if (payload.parent_type == 'opportunity') {
        formData.append('opportunity_id', payload.parent_id);
      } else if (payload.parent_type == 'announcement') {
        formData.append('announcement_id', payload.parent_id);
      } else if (payload.parent_type == 'bid') {
        formData.append('bid_id', payload.parent_id);
      } else if (payload.parent_type == 'page') {
        formData.append('page_id', payload.parent_id);
      }
      else {
        alert('ERROR - File parent is not defined');
        return;
      }
      // CONSUME API
      return new Promise((resolve, reject) => {
        context.commit('setLoadingOverlayOn', true);
        httpClient.post(context.rootState.BASE_URL + '/admin/document',
          formData,
          {
            headers: {
              // Send saved login token with code verification
              Authorization: `Bearer ${context.rootGetters.getAuthToken}`
            }
          })
          .then((response) => {
            console.log('File created successfully');
            console.log(response);
            context.commit('setLoadingOverlayOn', false);
            resolve(response);
          })
          .catch((error) => {
            context.dispatch('handleError', error);
            console.log(error.response);
            context.commit('setLoadingOverlayOn', false);
            reject();
          });
      });// PROMISE
    },
    editDocumentData() { },
    deleteDocument(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoadingOverlayOn', true);
        httpClient.delete(context.rootState.BASE_URL + '/admin/document/' + id,
          {
            headers: {
              // Send saved login token with code verification
              Authorization: `Bearer ${context.rootGetters.getAuthToken}`
            }
          })
          .then((response) => {
            console.log('File deleted');
            console.log(response);
            // context.commit('setLoadingOverlayOn', false);
            resolve(response);
          })
          .catch((error) => {
            context.dispatch('handleError', error);
            console.log(error.response);
            context.commit('setLoadingOverlayOn', false);
            reject();
          });
      });// PROMISE
    },
    exportData(context, type) {
      return new Promise((resolve, reject) => {
        context.commit('setLoadingOverlayOn', true);
        httpClient.get(context.rootState.BASE_URL + '/admin/exported-data/' + type,
          {
            headers: {
              // Send saved login token with code verification
              Authorization: `Bearer ${context.rootGetters.getAuthToken}`
            },
            responseType: "blob",
          })
          .then((response) => {
            console.log('Export worked!');
            console.log(response.data);
            context.commit('setLoadingOverlayOn', false);
            resolve(response.data);
          })
          .catch((error) => {
            context.dispatch('handleError', error);
            console.log(error.response);
            context.commit('setLoadingOverlayOn', false);
            reject();
          });
      });// PROMISE
    },
    createAdmin(context, payload) {
      return new Promise((resolve, reject) => {
        context.commit('setLoadingOverlayOn', true);
        httpClient.post(context.rootState.BASE_URL + '/admin',
          payload,
          {
            headers: {
              // Send saved login token with code verification
              Authorization: `Bearer ${context.rootGetters.getAuthToken}`
            }
          })
          .then((response) => {
            alert('Admin created!');
            console.log(response);
            context.commit('setLoadingOverlayOn', false);
            resolve();
          })
          .catch((error) => {
            context.dispatch('handleError', error);
            console.log(error.response);
            context.commit('setLoadingOverlayOn', false);
            reject();
          });
      });// PROMISE
    },
  },
  modules: {
    auth: authStore,
    users: usersStore,
    opportunities: opportunitiesStore,
    bids: bidsStore,
    pages: pagesStore,
    data: dataStore,
    announcements: announcementsStore,
    notifications: notificationsStore,
    organizations: organizationsStore,
  },
  plugins: [vuexLocal.plugin],
})
