<template>
  <div id="main-view">
    <div id="seb-nav">
      <!-- IF NOT LOGGED IN -->
      <img v-if="!$store.getters.getIsLoggedIn" id="seb-nav-not-logged-logo" src="@/assets/pelican_logo.png" />

      <!-- IF LOGGED IN -->
      <div v-if="$store.getters.getIsLoggedIn">
        <ul id="seb-nav-sections">
          <li>
            <router-link to="/users" class="seb-nav-tab"
              :class="$store.getters.getSebNavCurrent == 'users' ? 'current' : ''"
              @click="$store.commit('setSebNavCurrent', 'users')">
              <img class="seb-nav-tab-icon" src="@/assets/icons/user-light-blue.svg" /><span>Users</span>
            </router-link>
          </li>
          <li>
            <router-link to="/organizations" class="seb-nav-tab"
              v-if="role === 'admin'"
              :class="$store.getters.getSebNavCurrent == 'organizations' ? 'current' : ''"
              @click="$store.commit('setSebNavCurrent', 'organizations')">
              <img class="seb-nav-tab-icon" src="@/assets/icons/org-light-blue.svg" /><span>Organizations</span>
            </router-link>
          </li>
          <li>
            <router-link to="/opportunities" class="seb-nav-tab"
              :class="$store.getters.getSebNavCurrent == 'opportunities' ? 'current' : ''"
              @click="$store.commit('setSebNavCurrent', 'opportunities')">
              <img class="seb-nav-tab-icon" src="@/assets/icons/opportunity-light-blue.svg" /><span>Opportunities</span>
            </router-link>
          </li>
          <li>
            <router-link to="/announcements" class="seb-nav-tab"
              :class="$store.getters.getSebNavCurrent == 'announcements' ? 'current' : ''"
              @click="$store.commit('setSebNavCurrent', 'announcements')">
              <img class="seb-nav-tab-icon" src="@/assets/icons/announcement-light-blue.svg" /><span>Announcements</span>
            </router-link>
          </li>
          <li>
            <router-link to="/notifications" class="seb-nav-tab"
              :class="$store.getters.getSebNavCurrent == 'notifications' ? 'current' : ''"
              @click="$store.commit('setSebNavCurrent', 'notifications')">
              <img class="seb-nav-tab-icon" src="@/assets/icons/alert-light-blue.svg" /><span>Notifications</span>
            </router-link>
          </li>
          <li>
            <router-link to="/pages" class="seb-nav-tab"
              :class="$store.getters.getSebNavCurrent == 'pages' ? 'current' : ''"
              @click="$store.commit('setSebNavCurrent', 'pages')">
              <img class="seb-nav-tab-icon" src="@/assets/icons/page-light-blue.svg" /><span>Pages</span>
            </router-link>
          </li>
          <li>
            <router-link to="/data-export" class="seb-nav-tab"
              :class="$store.getters.getSebNavCurrent == 'data-export' ? 'current' : ''"
              @click="$store.commit('setSebNavCurrent', 'data-export')">
              <img class="seb-nav-tab-icon" src="@/assets/icons/export-light-blue.svg" /><span>Data export &amp;
                analytics</span>
            </router-link>
          </li>
          <li id="seb-nav-branding">
            <ul>
              <li id="seb-nav-logo-li">
                <img id="seb-nav-logo" src="@/assets/pelican_logo.png" />
              </li>
              <li id="seb-nav-settings-li" class="seb-nav-settings-icons">
                <img id="seb-nav-settings" class="seb-nav-tab-icon" src="@/assets/icons/settings-light-blue.svg" />
              </li>
              <li id="seb-nav-alerts-li" class="seb-nav-settings-icons">
                <img id="seb-nav-alerts" class="seb-nav-tab-icon" src="@/assets/icons/alert-light-blue.svg" />
                <div id="seb-nav-alerts-pending"></div>
              </li>
            </ul>
          </li>

          <pre v-if="this.$store.getters.isDebug" class="language-json"><code>
          {{ this.$store.getters.getAdminUser }}
          </code></pre>

          <pre v-if="this.$store.getters.isDebug" class="language-json"><code>
          {{ this.$store.getters.getAdminRole }}
          </code></pre>
        </ul>

        <div id="seb-nav-footer">
          <a id="seb-nav-logout" class="seb-nav-tab" @click="$store.dispatch('handleSessionEnd')">
            <img class="seb-nav-tab-icon" src="@/assets/icons/log-out-light-blue.svg" /><span>Log out</span>
          </a>
          <p id="seb-nav-version">Admin v{{ $store.state.appVersion }}</p>
        </div>

      </div><!-- END IF LOGGED IN -->

    </div>

    <div id="seb-view">
      <div id="seb-breadcrumb" v-if="$store.getters.getIsLoggedIn && $store.getters.getShowBackButton">
        <button class="seb-button button-icon-left inverse" @click="$store.dispatch('goBack')"
          v-if="$store.getters.getShowBackButton"><img class="button-icon button-icon-left"
            src="@/assets/icons/arrow-light-blue-left.svg" />
          BACK</button><!--<p id="seb-breadcrumb-trail"> SEBconnect<span> / </span>Users<span> / </span> Single user</p>-->
      </div>
      <router-view />
    </div>

    <div id="seb-loading-overlay" v-if="$store.getters.getLoadingOverlayOn">
      <LoadingSpinner id="seb-loading-overlay-spinner"></LoadingSpinner>
    </div>


  </div>

  <SebModal :show="openModal" @close-modal="openModal = false"></SebModal>
</template>

<script>
import LoadingSpinner from '@/components/general/loading-spinner.vue';
import SebModal from '@/components/general/seb-modal.vue';
import { setOptions, bootstrap } from 'vue-gtag';

export default {
  name: 'SEBadmin',
  components: {
    LoadingSpinner,
    SebModal
  },
  data() {
    return {
      openModal: false,
      backButton: false,
    }
  },
  computed: {
    role() {
      return this.$store.getters.getAdminRole;
    }
  },
  methods: {
  },
  created() {
    this.$store.dispatch('getSystemDefinitions');
  },
  watch: {
    '$store.getters.getAdminUser': function() {
      if (this.$store.getters.getAdminUser) {
        setOptions({
          config: {
            id: 'G-M9V4BRZJ17',
            'user_id': this.$store.getters.getAdminUser,
          }
        });

        bootstrap().then(() => {
          console.log('GTAG Bootstrapped');
        });
      }
    }
  },
}
</script>

<style>
@font-face {
  font-family: "Mulish";
  src: local("Mulish"),
    url(./assets/fonts/Mulish-VariableFont_wght.ttf) format("truetype");
  font-weight: 100 1000;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Mulish, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #103966;
}

pre code {
  font-size: 10px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
  font-family: Mulish, Helvetica, Arial, sans-serif;
  letter-spacing: 0.15px
}

h1 {
  font-size: 28px;
  margin: 0;
  padding: 10px 0 15px;
}

.group:after {
  /* CLEARFIX HACK*/
  content: "";
  display: table;
  clear: both;
}

.cover-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hide {
  display: none;
}

.lock-scroll {
  overflow: hidden;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

li.half {
  display: inline-block;
  vertical-align: bottom;
  width: calc(50%);
}

li.third {
  display: inline-block;
  vertical-align: bottom;
  width: calc(33.3% - 5px);
  margin-right: 2px;
}

li.two-thirds {
  display: inline-block;
  vertical-align: bottom;
  width: calc(66.6% - 6px);
}

li.fourth {
  display: inline-block;
  vertical-align: bottom;
  width: calc(25% - 6px);
}

li.fifth {
  display: inline-block;
  vertical-align: bottom;
  width: calc(20% - 6px);
}

li.sixth {
  display: inline-block;
  vertical-align: bottom;
  width: calc(16.65% - 6px);
}

.divider {
  height: 1px;
  background-color: #ccc;
  width: 100%;
  margin: 25px 0;
  display: block;
}

.divider.no-top {
  margin-top: 0;
}

.divider.no-bottom {
  margin-bottom: 0;
}

.seb-form-item h3 {
  padding: 0;
  margin: 0 0 15px;
  font-size: 17px;
}

.seb-form-item input[type=text],
.seb-form-item input[type=date],
.seb-form-item input[type=time],
.seb-form-item input[type=number],
.seb-form-item input[type=email],
.seb-form-item input[type=password],
.seb-form-item textarea {
  padding: 8px 12px;
  margin: 2px 0;
  border: solid 1px #999;
  border-radius: 10px;
  width: calc(100% - 30px);
  font-weight: 600;
  outline: none;
  color: #103966;
}

.seb-form-item input[type=date] {
  width: calc(100% - 30px);
}

.seb-form-item select {
  padding: 7px 6px;
  border-radius: 10px;
  border: solid 1px #999;
  width: calc(100% - 4px);
  outline: none;
  margin: 2px 0;
  font-family: Mulish, Helvetica, Arial, sans-serif;
  letter-spacing: 0.15px;
  color: #103966;
}

.seb-form-item select[multiple] {
  border-radius: 0px;
  height: 200px;
}

.seb-form-item select[multiple] option {
  font-size: 13px;
  padding: 4px;
}

.seb-form-item select[disabled] {
  background-color: #eee;
}

.seb-form-item label {
  font-size: 11px;
  letter-spacing: 0.15px;
  font-weight: 500;
  padding-left: 2px;
  color: #17639D;
}

#seb-login-form .seb-form-item input[type=text],
#seb-login-form .seb-form-item input[type=number],
#seb-login-form .seb-form-item input[type=email],
#seb-login-form .seb-form-item input[type=password] {
  border: solid 2px #999;
  border-radius: 80px;
  border-color: #DAB36F;
}

#seb-login-form .seb-form-item label {
  padding-left: 10px;
}

button {
  font-family: Mulish, Helvetica, Arial, sans-serif;
  letter-spacing: 0.15px;
  padding: 7px 12px;
  margin-top: 10px;
  background-color: #17639D;
  border: solid 2px #17639D;
  color: #fff;
  border-radius: 80px;
  min-width: 80px;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border 0.15s ease-in-out;
}

button:hover {
  background-color: #f6f6f6;
  color: #17639D;
}

button.inverse {
  background-color: transparent;
  border: solid 2px #17639D;
  color: #17639D;
}

button.inverse:hover {
  background-color: #F4ECD6;
  color: #17639D;
}

button.gold {
  background-color: #DAB36F;
  border: solid 2px #DAB36F;
  color: #103966;
}

button.gold:hover {
  background-color: #ECD3A4;
  border-color: #ECD3A4;
}

button.red {
  background-color: #FA2233;
  border: solid 2px #FA2233;
  color: #FFFFFF;
}

button.red:hover {
  background-color: #FFCCCC;
  color: #FA2233;
}

button:focus {
  outline: none;
}

button.nav-tab-button {
  background-color: #bbb;
  border-color: #bbb;
}

button.nav-tab-button:hover {
  background-color: #F4ECD6;
  border-color: #17639D;
}

button.nav-tab-button.current {
  background-color: #17639D;
  border-color: #17639D;
  color: #fff;
}

button.nav-tab-button span {
  display: inline-block;
  vertical-align: top;
  background-color: #eee;
  color: #aaa;
  border-radius: 12px;
  padding: 2px 5px;
  margin: 0 0 0 5px;
  font-size: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border 0.15s ease-in-out;
}

button.nav-tab-button:hover span {
  background-color: #17639D;
  color: #fff;
}

button.nav-tab-button.current span {
  background-color: #64CBFF;
  color: #17639D;
}

.button-icon {
  display: inline-block;
  vertical-align: top;
  width: 15px;
}

.button-icon-right {
  padding-left: 12px;
}

.button-icon-left {
  padding-right: 12px;
}

.top-buttons button,
.bottom-buttons button {
  margin: 8px 8px 8px 0;
}

/* File input */
.seb-form-item input[type=file] {
  display: block;
  width: 100%;
  padding-top: 5px;
}

.seb-form-item input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
}

.seb-form-item input[type=file]::before {
  content: 'Select a file';
  display: inline-block;
  /*background: linear-gradient(to bottom, #f9f9f9, #aaa);*/
  background-color: #eee;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  margin: 0;
  width: 72px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 600;
  font-size: 13px;
}

/*
.seb-form-item input[type=file]:hover::before {
  border-color: black;
}
.seb-form-item input[type=file]:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
*/

#seb-nav {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-image: linear-gradient(-7deg, #042340, #103966, #144C81, #175A95, #1175B4);
  background-color: #1c3e80;
  width: 300px;
  height: calc(100vh);
  overflow: auto;
}

#seb-nav p,
#seb-nav a,
#seb-nav ul li {
  color: #fff;
  font-family: Mulish, sans-serif;
  letter-spacing: 0.15px;
  font-weight: 600;
}

#seb-nav-sections {
  margin: 30px 20px 20px;
  padding: 0 0 30px;
  width: calc(100% - 40px);
  list-style: none;
}

.single-nav-tabs {
  margin-bottom: 10px;
}

.single-nav-tabs button {
  margin-right: 8px;
}

.seb-nav-tab {
  display: block;
  padding: 12px;
  margin: 8px 0;
  /*border:solid 1px #224185;*/
  border-radius: 40px;
  line-height: 0;
  transition: background-color 0.05s ease-in-out, color 0.05s ease-in-out;
  text-decoration: none;
}

.seb-nav-tab:hover {
  background-color: #103966;
}

.seb-nav-tab.current {
  background-color: #DAB36F;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

.seb-nav-tab-icon {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  margin: 0 10px 0 5px;
}

.seb-nav-tab:hover span {}

.seb-nav-tab.current span {
  color: #1c3e80;
}

.seb-nav-tab:hover img {}

.seb-nav-tab.current img {
  filter: hue-rotate(200deg) brightness(4) saturate(30%);
  opacity: 0.75;
}

#seb-nav-branding {
  padding: 25px 0;
  border-top: solid 1px #DAB36F;
  margin: 30px 0;
}

#seb-nav-branding ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

#seb-nav-logo-li {
  display: inline-block;
}

#seb-nav-logo {
  display: inline-block;
  vertical-align: middle;
  fill: white;
  width: 120px;
  padding: 0 70px;
}

.seb-nav-settings-icons {
  display: inline-block;
  vertical-align: middle;
}

.seb-nav-settings-icons .seb-nav-tab-icon {
  width: 30px;
  height: auto;
  display: none;
}

#seb-nav-footer {
  position: fixed;
  bottom: 0;
  width: 260px;
  padding: 0 20px;
  background-color: #07223E;
}

#seb-nav-footer .seb-nav-tab {
  display: inline-block;
  width: 105px;
}

#seb-nav-footer p {
  display: inline-block;
  width: 115px;
  text-align: right;
  font-size: 12px;
  opacity: 0.3;
}

#seb-view {
  margin-left: 300px;
  padding: 25px;
  width: calc(100% - 300px - 50px);
}

#seb-nav-logout {
  cursor: pointer;
}

#seb-nav-not-logged-logo {
  display: block;
  fill: #fff;
  width: 220px;
  position: absolute;
  top: calc(50vh - 110px);
  left: calc(50% - 110px)
}

#seb-loading-overlay,
#seb-confirm-overlay {
  position: fixed;
  z-index: 9999998;
  background-color: #152838;
  opacity: 0.9;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

#seb-confirm-overlay {
  opacity: 1;
  z-index: 9999999;
}

#seb-loading-overlay-spinner {
  margin-top: calc(50vh - 40px);
  margin-left: calc(50vw - 40px);
}

/* SEARCH BAR */
.seb-search-bar {
  background-color: #F4ECD6;
  padding: 15px;
  border-radius: 9px;
}

.seb-search-bar input[type='text'] {
  border-radius: 8px;
  border-color: #fff;
  padding-left: 40px;
  width: calc(100% - 56px);
}

.seb-select-org {
  background-color: #F4ECD6;
  padding: 15px;
  border-radius: 9px;
}

.seb-select-org input[type='text'] {
  border-radius: 8px;
  border-color: #fff;
  width: calc(100% - 56px);
}

#seb-breadcrumb {
  padding: 0 0 15px 0;
}

#seb-breadcrumb button {
  margin-right: 15px;
}

#seb-breadcrumb-trail {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  font-size: 13px;
  padding-bottom: 2px;
  color: #17639D;
}

#seb-breadcrumb-trail span {
  font-weight: 900;
  color: #2CB0F4;
  padding: 0 4px;
}

.seb-page-title {
  color: #103966;
  margin: 0 0 20px;
  padding: 5px 0 15px;
  font-size: 20px;
  text-transform: uppercase;
  border-bottom: solid 1px #17639D;
}

.seb-term-pill {
  display: inline-block;
  vertical-align: top;
  padding: 4px 8px 5px 10px;
  margin: 5px;
  border-radius: 80px;
  background-color: #F4ECD6;
  border: solid 1px #ECD3A4;
}

.seb-term-pill.disabled {
  opacity: 0.65;
}

.seb-term-pill .term-pill-text {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.seb-term-pill .term-pill-remove {
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  padding: 3px 6px;
  margin-left: 5px;
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  background-color: rgba(10, 5, 0, 0.2);
  cursor: pointer;
}

.seb-term-pill .term-pill-remove:hover {
  background-color: rgba(210, 5, 0, 0.8);
}

.seb-term-pill.disabled .term-pill-remove:hover {
  background-color: rgba(0, 0, 0, 0.20);
}

/* TIPTAP / ProseMirror */
.ProseMirror {
  padding: 5px 8px;
}

.ProseMirror p,
.ProseMirror li {
  font-size: 14px;
}

.ProseMirror h3 {
  font-size: 20px;
  font-weight: 800;
  margin: 10px 0;
}

.ProseMirror h4 {
  font-size: 17px;
  font-weight: 700;
}

.ProseMirror ul {
  margin: 0;
  padding: 0 0 0 40px;
  list-style: disc;
}

.ProseMirror ul,
.ProseMirror ol {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ProseMirror li p {
  margin: 0 0 5px;
  padding: 0;
}

.ProseMirror[contenteditable="false"] {
  background-color: #f5f5f5;
}

.ProseMirror[contenteditable="false"]:focus {
  outline: none;
}

/* BID LISTINGS */
.seb-bid-item {
  padding: 5px;
  border-radius: 10px;
  margin: 5px 0;
  border: solid 1px #dedede;
  border-radius: 10px;
  transition: background-color 0.15s ease-in-out,
    border 0.15s ease-in-out;
  width: calc(100% - 30px);
  cursor: pointer;
}

.seb-bid-item:hover {
  border-color: #17639D;
  background-color: #fff;
  border-bottom: solid 2px #17639D;
}

/* SELECTED USI */
.seb-user-selected-usi {}

.seb-user-selected-usi li {
  padding: 10px 30px 10px 10px;
  background-color: #f2f2f2;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  margin: 4px 0;
  position: relative;
  width: calc(100% - 40px);
}

.seb-user-selected-usi li span {
  display: inline-block;
  vertical-align: middle;
}

.seb-user-selected-usi li img.delete {
  display: block;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 7px;
  right: 5px;
  padding: 3px;
  border-radius: 20px;
  cursor: pointer;
}

.seb-user-selected-usi li img.delete:hover {
  background-color: #ccc;
}</style>
