<template>
  <transition name="fade">
    <div class="modal" v-if="show" :class="noSummary ? 'no-summary' : ''">
      <div class="modal__backdrop" @click="closeModal()"/>

      <div class="modal__dialog">

        <div class="modal__header">
          <button type="button" class="modal__close" @click="closeModal()">
              CLOSE &nbsp;X
          </button>
          <slot name="header"/>
        </div>

        <div class="modal__body">
          <slot name="body"/>
        </div>

        <div class="modal__footer">
          <slot name="footer"/>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SebModal",
  props: {
      show: {
          type:Boolean,
          default: false,
      },
      noSummary:{
          type:Boolean,
          default: false,
      },
  },
  methods: {
      closeModal(){
          this.$emit('closeModal');
          this.$store.commit('setLockScroll', false);
      }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modal {
    width:calc(100% - 70px);
    height:calc(100vh - 125px);
    background-color:#fff;
    padding:70px 15px 15px;
    margin: 20px;
    box-shadow:0px 0px 30px #000;
    position:fixed;
    z-index:9999999999;
    top:0;
    left:0;
    overflow-y:auto;
}
.modal.no-summary {
  padding:15px;
  height:calc(100vh - 50px);
}
.modal__header {
    border-bottom: solid 1px #ddd;
    padding:10px 0;
    position:fixed;
    top:20px;
    width:calc(100% - 70px);
    background-color:#fff;
    z-index:9999999;
}
</style>