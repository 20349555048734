import { httpClient } from '../../utilities/httpClient.js';
const pagesStore = {
    state() {
        return {
            pages: [],
            currentPage:'',
            pagesHaveLoaded: false,
            currPageDocs: []
        }
    },
    getters: {
        getPagesList(state) {return state.pages},
        getCurrentPage(state) {return state.currentPage},
        getCurrentPageDocs(state) {return state.currentPageDocs},
        getPagesHaveLoaded(state) {return state.pagesHaveLoaded},
    },
    actions: {
        getPages(context){
            // Construct the URL based on type of bid entity (user or opportunity)
            // entity: {type, id}
            var queryURL = context.rootState.BASE_URL + '/admin/pages?lower_date=2021-01-01';
            return new Promise((resolve, reject) => {
                context.commit('setPagesHaveLoaded', false);
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(queryURL, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Pages loaded:');
                    console.log(response.data.data);
                    context.commit('setPagesHaveLoaded', true);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setPages', response.data.data);
                    context.dispatch('generatePageSlugs');
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setPagesHaveLoaded', false);
                    reject();
                }); 
            });// PROMISE
        },
        generatePageSlugs(context){
            context.state.pages.forEach((p, index) => {
                context.state.pages[index].slug = p.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-').toLowerCase();
            });
        },
        createPage(context, title){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.post(context.rootState.BASE_URL + '/admin/page', 
                {
                    title: title,
                    platforms: [],
                    layout: [],
                    active: false,
                },
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Page created!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        editPage(context, payload){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.put(context.rootState.BASE_URL + '/admin/page/' + payload.id, 
                payload.data,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    alert('Page edited successfully!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        getPageDocs(context, id){
            return new Promise((resolve, reject) => {
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/admin/documents?page_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Got page docs');
                    console.log(response);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setCurrentPageDocs', response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
    },
    mutations: {
        setPages(state, pages) { state.pages = pages },
        setCurrentPage(state, currentPage) { state.currentPage = currentPage },
        setCurrentPageDocs(state, currentPageDocs) { state.currentPageDocs = currentPageDocs },
        setPagesHaveLoaded(state, pagesHaveLoaded) {state.pagesHaveLoaded = pagesHaveLoaded},
    }
};

export default pagesStore;
