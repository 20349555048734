import { httpClient } from '../../utilities/httpClient.js';
const opportunitiesStore = {
    state() {
        return {
            opportunities: [],
            currentOpportunityDocs: [],
            opportunitiesHaveLoaded: false,
            opportunitiesLastLoaded:0,
        }
    },
    getters: {
        getOpportunitiesList(state) {return state.opportunities},
        getCurrOpportunityDocs(state) {return state.currentOpportunityDocs},
        getOpportunitiesHaveLoaded(state) {return state.opportunitiesHaveLoaded},
        getOpportunitiesLastLoaded(state) {return state.opportunitiesLastLoaded},
    },
    actions: {
        getAllOpportunities(context){
            // IF NOT ENOUGH TIME HAS PASSED, RELOAD FROM STORE
            /*if(parseInt(new Date().getTime()) - parseInt(context.getters.getUsersLastLoaded) < 5000){
                return;
            }*/
            // ELSE RELOAD FROM SERVER
            return new Promise((resolve, reject) => {
                context.commit('setOpportunitiesHaveLoaded', false);
                context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/admin/opportunities?lower_date=2022-12-01', { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    context.commit('setOpportunitiesHaveLoaded', true);
                    context.commit('setLoadingOverlayOn', false);
                    context.commit('setOpportunitiesLastLoaded', new Date().getTime());
                    context.commit('setOpportunitiesList', response.data.data);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        getSingleOpportunity(context, id){
            // IF NOT ENOUGH TIME HAS PASSED, RELOAD FROM STORE
            /*if(parseInt(new Date().getTime()) - parseInt(context.getters.getUsersLastLoaded) < 5000){
                return;
            }*/
            // ELSE RELOAD FROM SERVER
            return new Promise((resolve, reject) => {
                httpClient.get(context.rootState.BASE_URL + '/admin/opportunities?opportunity_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    reject();
                }); 
            });// PROMISE
        },
        createOpportunity(context, payload){
            console.log("payload " + payload);

            //payload.categorization = JSON.stringify(payload.categorization);
            //payload.content = JSON.stringify(payload.content);

            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.post(context.rootState.BASE_URL + '/admin/opportunity', 
                // Payload
                /* 
                {
                    title: string,
                    state: 'created'
                }
                 */
                payload,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Opportunity created!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve(response);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        editOpportunity(context, payload){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.put(context.rootState.BASE_URL + '/admin/opportunity/' + payload.id, 
                // Payload
                /* 
                {
                    state: string,
                    start_date: string,
                    end_date: string,
                    location: string,
                    content: { // Make sure to encode before adding to JSON
                        title: string,
                        
                    }
                    categorization: { // Make sure to encode before adding to JSON
                        naics: [string],
                        usi_codes: [string],
                        registration_type: [string],
                        seb_certification: [string],
                        tags: [string]
                    }
                }
                 */
                payload.data,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    alert('Opportunity edited!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    // Update user in list?
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        getOpportunityDocs(context, id){
            return new Promise((resolve, reject) => {
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/admin/documents?opportunity_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setCurrOpportunityDocs', response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        getOpportunityBidTemplate(context, id){
            return new Promise((resolve, reject) => {
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/admin/opportunity/forms?form_template_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log("BID FORM TEMPLATE:");
                    console.log(response.data.data[0]);
                    // context.commit('setLoadingOverlayOn', false);
                    resolve(response.data.data[0]);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        }
    },
    mutations: {
        setOpportunitiesList(state, opportunities) { state.opportunities = opportunities },
        setCurrOpportunityDocs(state, currentOpportunityDocs) {state.currentOpportunityDocs = currentOpportunityDocs},
        setOpportunitiesHaveLoaded(state, opportunitiesHaveLoaded) {state.opportunitiesHaveLoaded = opportunitiesHaveLoaded},
        setOpportunitiesLastLoaded(state, opportunitiesLastLoaded) {state.opportunitiesLastLoaded = opportunitiesLastLoaded},
    }
};

export default opportunitiesStore;
