import { httpClient } from '../../utilities/httpClient.js';
import router from '../../router/index.js';
import { jwtDecode } from 'jwt-decode';

const authStore = {
    // ONLY MANAGE AUTH-RELATED ITEMS HERE
    state() {
        return {
            isLoggedIn: false,
            lastAuth: '0', // timestamp
            authToken: 'none', // session token
            authTokenTtl: 0,
            adminUser: 'none',
            adminRole: 'org_admin'
        }
    },
    getters: {
        getIsLoggedIn(state) {return state.isLoggedIn},
        getLastAuth(state) {return state.lastAuth},
        getAuthToken(state) {return state.authToken},
        getAuthTokenTtl(state) {return state.authTokenTtl},
        getAdminUser(state) {return state.adminUser},
        getAdminRole(state) {return state.adminRole},
    },
    actions: {

        // POST ADMIN LOGIN WITH USERNAME AND PASSWORD
        adminSendUserLogin(context, payload){
            context.commit('setLoadingOverlayOn', true);
            return new Promise((resolve) => {
                httpClient.post(context.rootState.BASE_URL + '/admin/login', {
                    'email': payload.username,
                    'password': payload.password
                }).then((response)=>{

                    if(response.data.data.admin_id){
                        // IF SUCCESS
                        console.log('SUCCESS with login credentials');
                        // console.log(response.data.data.token);
                        // console.log(response);

                        const decoded = jwtDecode(response.data.data.token);
                        console.log('DECODED', decoded);

                        // Save login token for use in code verification
                        context.commit('setAuthToken', response.data.data.token);
                        context.commit('setAdminUser', response.data.data.admin_id);
                        context.commit('setAuthTokenTtl', response.data.data.tl_token);
                        context.commit('setAdminRole', decoded.sub_rol);
                        context.commit('setIsLoggedIn', true);
                        context.commit('setLastAuth', new Date().getTime());
                        // Make sure to turn false in once the users page is loaded
                        // context.commit('setLoadingOverlayOn', false);
                        resolve();
                    } else {
                        // ERROR
                        throw 401
                    }
                })
                .catch(()=>{
                    alert('Error: undefined');
                    context.commit('setLoadingOverlayOn', false);
                    resolve();
                });
            });//PROMISE
        },
        // HANDLE END OF SESSION 
        handleSessionEnd(context){
            // Invalidate token, chnage auth state in store, go to login view
            httpClient.get(context.rootState.BASE_URL + '/admin/logout',
                { headers: {
                    Authorization: `Bearer ${context.rootGetters.getAuthToken}`,
                } 
            })
            .then(()=>{
                // NAVIGATE TO HOME VIEW
                context.commit('setIsLoggedIn', false);
                context.commit('setAuthToken', 'none');
                context.commit('setAuthTokenTtl', 0);
                context.commit('setAdminUser', 'none');
                router.push({ path: '/', replace: true });
            })
            .catch((/*error*/)=>{
                console.log('invalid TOKEN');
                // NAVIGATE TO HOME VIEW ALSO
                context.commit('setIsLoggedIn', false);
                context.commit('setAuthToken', 'none');
                context.commit('setAuthTokenTtl', 0);
                context.commit('setAdminUser', 'none');
                router.push({ path: '/', replace: true });
                
            });
        },
        adminResetPassword(context, payload){
            context.commit('setLoadingOverlayOn', true);
            return new Promise((resolve) => {
                httpClient.post(context.rootState.BASE_URL + '/admin/password', {
                    'email': payload.email,
                }).then((response)=>{
                        // IF SUCCESS
                        console.log('SUCCESS with password reset');
                        context.commit('setLoadingOverlayOn', false);
                        resolve(response);
                })
                .catch(()=>{
                    alert('Error: resetPassword');
                    context.commit('setLoadingOverlayOn', false);
                    resolve();
                });
            });//PROMISE
        },
    },
    mutations: {
        setIsLoggedIn(state,isLoggedIn) {state.isLoggedIn = isLoggedIn},
        setLastAuth(state, lastAuth) {state.lastAuth = lastAuth},
        setAuthToken(state, authToken) {state.authToken = authToken},
        setAuthTokenTtl(state, authTokenTtl) {state.authTokenTtl = authTokenTtl},
        setAdminUser(state, adminUser) {state.adminUser = adminUser},
        setAdminRole(state, adminRole) {state.adminRole = adminRole},
    }
};

export default authStore;

// PENDIENTES
// 1. Recuperación de contraseña? o por ahora manual
// 2. Cambios de credenciales? o por ahora manual
// Verificar si se debe incluir, o por ahora se excluye del sistema
