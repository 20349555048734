import { httpClient } from '../../utilities/httpClient.js';
const usersStore = {
    state() {
        return {
            users: [],
            usersHaveLoaded: false,
            usersLastLoaded: 0,
        }
    },
    getters: {
        getUsersList(state) {return state.users},
        getUsersHaveLoaded(state) {return state.usersHaveLoaded},
        getUsersLastLoaded(state) {return state.usersLastLoaded},
    },
    actions: {
        getAllUsers(context){
            // IF NOT ENOUGH TIME HAS PASSED, RELOAD FROM STORE
            if(parseInt(new Date().getTime()) - parseInt(context.getters.getUsersLastLoaded) < 5000){
                return;
            }
            // ELSE RELOAD FROM SERVER
            return new Promise((resolve, reject) => {
                context.commit('setUsersHaveLoaded', false);
                context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/admin/users?lower_date=2020-01-01', { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    context.commit('setUsersHaveLoaded', true);
                    context.commit('setLoadingOverlayOn', false);
                    context.commit('setUsersLastLoaded', new Date().getTime());
                    context.commit('setUsersList', response.data.data);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        deleteUser(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.delete(context.rootState.BASE_URL + '/admin/user/' + payload, 
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    alert('User deleted!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });
        },
        createUser(context, payload){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.post(context.rootState.BASE_URL + '/admin/user', 
                // Payload
                /* 
                {
                    name: string,
                    last_name: string,
                    email: string,
                    password: string,
                    phone: string,
                    company_name: string,
                    file: file, // Profile photo
                    file_name: string, // profile photo
                    creation_platform: 'admin' // inject before sending,
                    address: { // Make sure to encode before adding to JSON
                        line_1: string,
                        line_2: string,
                        city: string,
                        state: string,
                        zip_code: string,
                    }
                    preferences: { // Make sure to encode before adding to JSON
                        naics: [string], // if old user only
                        usi_codes: ["string"],
                        notification: {
                            within: string,
                            outside: string
                        },
                        registration_type: string,
                        seb_certification: string,
                    }
                    detailed_description_of_services: 'string',
                    state: string,
                    registration_push_token: string // can't do it from admin, user must subscribe
                }
                 */
                payload,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    alert('User created!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve(response.data.data.user_id);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        editUser(context, payload){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.put(context.rootState.BASE_URL + '/admin/user/' + payload.id, 
                // Payload
                /* 
                {
                    name: string,
                    last_name: string,
                    email: string,
                    password: string,
                    phone: string,
                    company_name: string,
                    file: file, // Profile photo
                    file_name: string, // profile photo
                    creation_platform: 'admin' // inject before sending,
                    address: { // Make sure to encode before adding to JSON
                        line_1: string,
                        line_2: string,
                        city: string,
                        state: string,
                        zip_code: string,
                    }
                    preferences: { // Make sure to encode before adding to JSON
                        naics: [string],
                        usi_codes:[string],
                        notification: {
                            within: string,
                            outside: string
                        },
                        registration_type: string,
                        seb_certification: string,
                    }
                    detailed_description_of_services: 'string',
                    state: string,
                    registration_push_token: string // can't do it from admin, user must subscribe
                }
                 */
                payload.data,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    alert('User edited!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    // Update user in list?
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
    },
    mutations: {
        setUsersList(state, users) { state.users = users },
        setUsersHaveLoaded(state, usersHaveLoaded) {state.usersHaveLoaded = usersHaveLoaded},
        setUsersLastLoaded(state, usersLastLoaded) {state.usersLastLoaded = usersLastLoaded},
    }
};

export default usersStore;
