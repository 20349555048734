import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag"
import store from './store'

// createApp(App).use(store).use(router).mount('#app')

const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");

app.use(VueGtag, {
    appName: 'SEBadmin',
    pageTrackerScreenviewEnabled: true,
    bootstrap: false,
  }, router);