<template>
  <div class="home">
    <div id="seb-login-form">
      <h1 id="seb-login-title" v-if="!resetMode">ADMIN LOGIN</h1>
      <h1 id="seb-login-title" v-if="resetMode">PASSWORD RESET</h1>
      <ul>
        <li class="seb-form-item">
          <input type="text" placeholder="Username" v-model="username"/>
        </li>
        <li class="seb-form-item" v-if="!resetMode">
          <input type="password" placeholder="Password" v-model="password" />
        </li>
        <li id="seb-login-submit" class="seb-form-item" v-if="!resetMode">
          <a id="seb-forgot-password" @click="resetMode = true">Forgot your password?</a><button class="seb-button gold" @click="sendLogin">LOG IN<img class="button-icon button-icon-right" src="@/assets/icons/arrow-white-right.svg"/></button>
        </li>
        <li id="seb-login-submit" class="seb-form-item" v-if="resetMode">
          <a id="seb-forgot-password" @click="resetMode = false">CANCEL</a><button class="seb-button gold" @click="resetPassword">RESET<img class="button-icon button-icon-right" src="@/assets/icons/arrow-white-right.svg"/></button>
        </li>

      </ul>
    </div>

    <p v-if="false">{{username}} : {{password}}</p>

    <div v-if="false">
      <p>Token: {{ $store.getters.getAuthToken }}</p>
      <p>Ttl: {{ $store.getters.getAuthTokenTtl }}</p>
      <p>Admin: {{ $store.getters.getAdminUser }}</p>
      <p>Last login: {{ $store.getters.getLastAuth }}</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  data(){
    return {
      username: 'carlos@gmcadvertising.com',
      password: 'DCUiyS2og',
      resetMode: false,
    }
  },
  methods: {
    sendLogin(){
      // SANITIZE AND PROTECT PASSWORD FIELD
      // THEN SUBMIT
      this.$store.dispatch('adminSendUserLogin', {username: this.username, password: this.password})
      .then(()=>{
        this.password = '';
        this.$router.push('/users');
      });
    },
    resetPassword(){
      this.$store.dispatch('adminResetPassword', {email: this.username})
      .then(()=>{
        alert('Check your email for instructions');
        this.resetMode = false;
      });
    }
  }
}
</script>

<style scoped>
#seb-login-title {
  color:#DAB36F;
  font-size:18px;
  letter-spacing:3px;
  font-weight:700;
  margin:0;
  padding-bottom: 25px;
  padding-left:8px;
}
#seb-login-form {
  width:300px;
  margin-top:calc(50vh - 115px);
  margin-left: 40px;
}
#seb-login-form ul {
  padding:0;
  margin:0;
  list-style:none;
}
#seb-login-form ul li{
  margin: 0 0 4px 0;
}
#seb-login-form ul li input{
  border-color:#DAB36F;
  font-weight:700;
}
#seb-forgot-password {
  color:#2CB0F4;
  font-size:13px;
  font-weight:600;
  padding-left: 10px;
  margin-right:40px;
}
#seb-login-form ul li input::placeholder {
  color: #103966;
  opacity:1;
}
#seb-login-form ul li input:focus::placeholder {
  opacity:0.33
}

</style>
