import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store/index.js';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admins/new-admin',
    name: 'NewAdmin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/NewAdmin.vue')
  },
  {
    path: '/users',
    name: 'Users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/Users.vue')
  },
  {
    path: '/users/new-user',
    name: 'NewUser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/NewUser.vue')
  },
  {
    path: '/users/single-user',
    name: 'SingleUser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/SingleUser.vue')
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/Opportunities.vue')
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import('../views/Organizations.vue')
  },
  {
    path: '/opportunities/single-opportunity',
    name: 'SingleOpportunity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/SingleOpportunity.vue')
  },
  {
    path: '/organizations/single-organization',
    name: 'SingleOrganization',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/SingleOrganization.vue')
  },
  {
    path: '/opportunities/new-opportunity',
    name: 'NewOpportunity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/NewOpportunity.vue')
  },
  {
    path: '/organizations/new-organization',
    name: 'NewOrganization',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/NewOrganization.vue')
  },
  {
    path: '/pages',
    name: 'Pages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/Pages.vue')
  },
  {
    path: '/pages/new-page',
    name: 'NewPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/NewPage.vue')
  },
  {
    path: '/pages/single-page/:id',
    name: 'SinglePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/SinglePage.vue')
  },
  {
    path: '/data-export',
    name: 'DataExport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/DataExport.vue')
  },
  {
    path: '/announcements',
    name: 'Announcements',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/Announcements.vue')
  },
  {
    path: '/announcements/new-announcement',
    name: 'NewAnnouncement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/NewAnnouncement.vue')
  },
  {
    path: '/announcements/single-announcement/:id',
    name: 'SingleAnnouncement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/SingleAnnouncement.vue')
  },
  {
    path: '/notifications',
    name: 'Notifications',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/Notifications.vue')
  },
  {
    path: '/notifications/new-notification',
    name: 'NewNotification',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/NewNotification.vue')
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  routes,
  history: createWebHashHistory(),
});

router.beforeEach(async function(to, from, next){
  // console.log(to);
  // console.log(from);
  // SHOW SPINNER WHILE CHECK HAPPENS
  store.commit('setNavInProgress', true);

  var checkResult = store.getters.getIsLoggedIn;

  // console.log('checkResult: ' + checkResult);
  if(to.fullPath === '/' || to.fullPath === '/login' || to.fullPath === '/admin/login'){
    // GOING TO LOGIN/HOME
    if(!store.getters.getIsLoggedIn){
      next();
    } else {
      next('/users');
    }
    
  } else {
    // GOING TO ANOTHER ROUTE
    // TO DO: add conditional verification if more than 20 minutes have passed since last auth
    if(checkResult) {
      // ALLOW NAVIGATION
        next();
    } else {
      // BACK TO LOGIN
        console.log('DENIED PASSAGE - START OVER');
        next('/');
    }
}
// HIDE SPINNER
store.commit('setNavInProgress', false);
});



export default router
