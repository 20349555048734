import { httpClient } from '../../utilities/httpClient.js';
const announcementsStore = {
    state() {
        return {
            announcements: [],
            currentAnnouncement:'',
            currentAnnouncementDocs:[],
            announcementsHaveLoaded: false
        }
    },
    getters: {
        getAnnouncementsList(state) {
            let sorted = state.announcements.sort((a,b)=> {
            return b.post_date > a.post_date ? 1 : -1
          });
          console.log(sorted);
          return sorted
        },
        getCurrentAnnouncement(state) {return state.currentAnnouncement},
        getCurrentAnnouncementDocs(state) {return state.currentAnnouncementDocs},
        getAnnouncementsHaveLoaded(state) {return state.announcementsHaveLoaded},
    },
    actions: {
        getAnnouncements(context){
            // Construct the URL based on type of bid entity (user or opportunity)
            // entity: {type, id}
            var queryURL = context.rootState.BASE_URL + '/admin/announcements?lower_date=2021-01-01';
            return new Promise((resolve, reject) => {
                context.commit('setAnnouncementsHaveLoaded', false);
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(queryURL, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Announcements loaded:');
                    console.log(response.data.data);
                    context.commit('setAnnouncementsHaveLoaded', true);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setAnnouncements', response.data.data);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setAnnouncementsHaveLoaded', false);
                    reject();
                }); 
            });// PROMISE
        },
        createAnnouncement(context, title){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.post(context.rootState.BASE_URL + '/admin/announcement', 
                {
                    title: title,
                },
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Announcement created!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        editAnnouncement(context, payload){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.put(context.rootState.BASE_URL + '/admin/announcement/' + payload.id, 
                payload.data,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    alert('Announcement edited successfully!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        deleteAnnouncement(context, payload){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.delete(context.rootState.BASE_URL + '/admin/announcement/' + payload.id, 
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        getAnnouncementDocs(context, id){
            return new Promise((resolve, reject) => {
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/admin/documents?announcement_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Got announcement docs:');
                    console.log(response);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setCurrentAnnouncementDocs', response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
    },
    mutations: {
        setAnnouncements(state, announcements) { state.announcements = announcements },
        setCurrentAnnouncement(state, currentAnnouncement) { state.currentAnnouncement = currentAnnouncement },
        setCurrentAnnouncementDocs(state, currentAnnouncementDocs) { state.currentAnnouncementDocs = currentAnnouncementDocs },
        setAnnouncementsHaveLoaded(state, announcementsHaveLoaded) {state.announcementsHaveLoaded = announcementsHaveLoaded},
    }
};

export default announcementsStore;
